import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,
  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

import HeroMobile from "./../images/Pielonefral-mobile.jpg"
import HeroTablet from "./../images/Pielonefral-tablet.jpg"
import HeroDesktop from "./../images/Pielonefral-desktop.jpg"
import HeroXL from "./../images/Pielonefral-desktop-xl.jpg"
import Feature1 from "./../images/pielonefral-feature.jpg"
import Feature4 from "./../images/prost-psor-plus-herbal-guarantee.jpg"

const PielonefralPage = () => (
  <Layout backButton={true} pageInfo={{ pageName: "Pielonefral" }}>
    <Seo title="Пиелонефрал" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <Breadcrumb.Item active>ПИЕЛОНЕФРАЛ</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={HeroXL} media="(min-width: 1200px)" />
            <source srcSet={HeroDesktop} media="(min-width: 992px)" />
            <source srcSet={HeroTablet} media="(min-width: 767px)" />
            <source srcSet={HeroMobile} media="(min-width: 370px)" />
            <img
              srcSet={HeroMobile}
              alt="Пиелонефрал"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Пиелонефрал
          </h5>
          <h1 className="display-4 font-light">За бъбреци</h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">Пиелонефрал</h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            За бъбреци
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Билкова формула в помощ на бъбреците, пикочния мехур и
            пикочопровода.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/bg/pielonefral"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Купете онлайн от <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Продава се в аптеките в цялата страна</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>без разтворители</li>
                <li>без консерванти</li>
                <li>без глутен</li>
                <li>без захар</li>
                <li>без ГМО</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Без проблеми с бъбреците</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Без проблеми с бъбреците"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Пиелонефрал е натурален продукт, който спомага за поддържане
            нормалното функционално състояние на бъбреците и пикочните пътища.
            Подпомага отделителната бъбречна функция. Пиелонефрал спомага за
            премахването на бактериите, причинители на уроинфекции и облекчава
            неприятните симптоми като болка и парене при уриниране.
          </p>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Гаранция за качество и сигурност</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Гаранция за качество и сигурност"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Стандартизирани растителни екстракти се получават от растения или
            техни части чрез извличане на биологично активни компоненти. Тяхното
            приемущество е, че вида на активните вещества може да се контролира
            и поддържа в определено количество и качество.
          </p>
          <p>
            Приемът на екстракт с доказан ефект гарантира оптимално въздействие
            върху функциите на организма. Растителните екстракти, включени в
            Пиелонефрал допълват взаимно благоприятните си ефекти.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Повече за Пиелонефрал</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Състав
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Мечо грозде листа</strong> (Folia Uve ursi) 66,0 mg
                  </p>
                  <p>
                    <strong>Полски хвощ стрък</strong> (Herba Equiseti arvensis)
                    49,5 mg
                  </p>
                  <p>
                    <strong>Червена боровинка листа</strong> (Folia Vitis idaei)
                    33,0 mg
                  </p>
                  <p>
                    <strong>Мащерка стрък</strong> (Herba Serpilli) 33,0 mg
                  </p>
                  <p>
                    <strong>Лайка цвят</strong> (Flores Chamomillae) 33,0 mg
                  </p>
                  <p>
                    <strong>Тиквени семки</strong> (Cucurbita maxima Duch) 33,0
                    mg
                  </p>
                  <p>
                    <strong>Бреза листа</strong> (Folia Betulae albae) 33,0 mg
                  </p>
                  <p>
                    <strong>Върба кори</strong> (Cortex Salicis) 26,4 mg
                  </p>
                  <p>
                    <strong>Пирей корени</strong> (Rhisoma Graminis Agropuri
                    repens) 23,1 mg
                  </p>
                  <p>Помощни вещества: малтодекстрин, магнезиев стеарат</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Прием
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>2 пъти по 1-2 таблетки след храна</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Противопоказания
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    Не са установени. Да не се превишава препоръчваната дневна
                    доза. Продуктът да не се използва като заместител на
                    разнообразното хранене.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Действие
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Пиелонефрал е натурален продукт, който спомага за поддържане
                    нормалното функционално състояние на бъбреците и пикочните
                    пътища. Подпомага отделителната бъбречна функция.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default PielonefralPage
